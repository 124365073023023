<template>
  <div
    :class="`justify-${align}`"
    class="flex gap-4 items-center py-1 font-mono text-xs text-gray-500 whitespace-nowrap rounded-md table-fixed"
  >
    <div v-if="showLevel">
      Nv.<span :class="{ 'line-through': result && result.level !== level }">{{
        level
      }}</span>
      <span v-if="result?.level !== level" class="pl-1">{{
        result?.level
      }}</span>
    </div>

    <div class="flex items-center">
      <template v-if="result">
        <div class="text-base text-black">
          <span v-if="result.amount === null" class="text-xs"
            >Niet beoordeeld</span
          >
          <span v-else>{{ result.amount }}</span>
        </div>
        <div v-if="result.amount">/{{ amount }}</div>
      </template>

      <div v-else class="text-base text-black">
        <span v-if="amount === null" class="text-xs">Niet beoordeeld</span>
        <span v-else>{{ amount }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, PropType } from 'vue'

  const props = defineProps({
    align: {
      type: String as PropType<'start' | 'end'>,
      default: 'start',
    },

    level: {
      type: String,
    },

    amount: {
      type: Number as PropType<number | null>,
    },

    result: {
      type: Object as PropType<Result | null>,
    },
  })

  const showLevel = computed(() => {
    if (props.result && props.result.amount === null) {
      return false
    }

    return props.level && props.amount !== null
  })
</script>
