import { createApp } from 'vue'
import { createClient, defaultPlugins, ClientPlugin } from 'villus'
import { createI18n } from 'vue-i18n'
import { createHead } from '@vueuse/head'

import * as Sentry from '@sentry/vue'

import messages from './messages.js'

import './index.css'
import router from './router'

import App from './App.vue'

const authPlugin: ClientPlugin = ({ opContext, afterQuery }) => {
  opContext.credentials = 'include'

  afterQuery((callback) => {
    if (callback && callback.error) {
      console.error(callback.error)
    }
  })
}

const client = createClient({
  url: import.meta.env.VITE_APP_API_URL,
  cachePolicy: 'cache-and-network',
  use: [authPlugin, ...defaultPlugins()],
})

const app = createApp(App)

Sentry.init({
  app,
  environment: import.meta.env.PROD ? 'production' : 'development',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: __APP_VERSION__,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.25,
  tracePropagationTargets: ['localhost', /^https\:\/\/.+\.ffwd\.school/],
  replaysSessionSampleRate: 0.25,
  replaysOnErrorSampleRate: 1.0,
})

const fallbackLocale = 'nl'
const localeCookie = document.cookie.match(/lang=([a-z]+)/)
const locale = localeCookie ? (localeCookie[1] as string) : fallbackLocale
const i18n = createI18n({ messages, locale, fallbackLocale, legacy: false })
const head = createHead()

app.use(router).use(client).use(head).provide('appVersion', __APP_VERSION__)

app.use(i18n).mount('#app')
