<template>
  <div>
    <template v-if="isDone">
      <session-sidebar
        v-if="showSidebar"
        :session="data.session"
        :competencies="competencies"
      />

      <session-content
        :competencies="competencies"
        :me="me"
        :session="data.session"
        :show-sidebar="showSidebar"
        :is-done="isDone"
        @create:attachment="createAttachment"
        @create:result="createResult"
        @delete:attachment="deleteAttachment"
        @delete:result="deleteResult"
        @delete:session="deleteSession"
        @finalize:session="finalizeSession"
        @start_finalizing:session="startFinalizingSession"
        @save:post="savePost"
        @show:sidebar="showSidebar = $event"
        @set:flash="emit('set:flash', $event)"
        @set:goals="setGoals"
      />

      <router-view
        :enabled="isDone"
        :me="me"
        :goal="routeGoal"
        :session="data.session"
        @save:post="savePost"
        @create:attachment="createAttachment"
        @delete:attachment="deleteAttachment"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, reactive, ref, onUnmounted, computed } from 'vue'
  import { useMutation, useQuery } from 'villus'
  import { useRoute, useRouter } from 'vue-router'
  import { useHead } from '@vueuse/head'

  import { Socket as PhxSocket } from 'phoenix'

  import Session from '../graphql/Session.graphql'
  import FinalizeSession from '../graphql/FinalizeSession.graphql'
  import StartFinalizingSession from '../graphql/StartFinalizingSession.graphql'
  import CreateResult from '../graphql/CreateResult.graphql'
  import DeleteResult from '../graphql/DeleteResult.graphql'
  import SavePost from '../graphql/SavePost.graphql'

  import CreateAttachment from '../graphql/CreateAttachment.graphql'
  import DeleteAttachment from '../graphql/DeleteAttachment.graphql'
  import DeleteSession from '../graphql/DeleteSession.graphql'

  import SessionContent from '../components/SessionContent.vue'
  import SessionSidebar from '../components/SessionSidebar.vue'

  const props = defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const emit = defineEmits(['set:flash'])
  const route = useRoute()
  const router = useRouter()
  const showSidebar = ref(false)

  const routeGoal = computed(() => {
    if (isDone.value) {
      return (data.value.session?.goals || []).find(
        (g: Goal) => g.id === route.params.goalId,
      )
    } else {
      return null
    }
  })

  // Get Session

  const sessionId = route.params.sessionId
  const variables = reactive({ id: sessionId })
  const {
    data,
    isDone,
    execute: refetchSession,
  } = useQuery({ query: Session, variables })

  // Manage Socket

  const userId = computed(() => props.me.id)
  const options = { params: { userId: userId.value } }
  const socket = new PhxSocket(import.meta.env.VITE_APP_WS_URL, options)
  socket.connect()

  const channel = socket.channel(`session:${sessionId}`)

  channel.on('create_result', (result: Result) => {
    const session = data.value.session as Session
    const goal = session.goals.find((goal) => goal.id === result.goal?.id)
    if (goal) {
      goal.result = result
    }
  })

  channel.on('delete_result', (result: Result) => {
    const session = data.value.session as Session
    const goal = session.goals.find((goal) => goal.id === result.goal?.id)
    if (goal) {
      goal.result = null
    }
  })

  channel.on('finalize_session', () => {
    refetchSession()
    /* const session = data.value.session as Session */
    /* session.goals.forEach((g) => (g.session.finalized = true)) */
    /* session.finalized = true */
  })

  channel.on('start_finalizing_session', () => {
    refetchSession()
    /* const session = data.value.session as Session */
    /* session.goals.forEach((g) => (g.session.state = 'finalizing')) */
    /* session.state = 'finalizing' */
  })

  channel.on('delete_session', () => {
    emit('set:flash', { kind: 'alert', msg: 'Dit assessment is verwijderd.' })
    router.replace({ name: 'home' })
  })

  channel.join()

  onUnmounted(() => {
    channel.leave().receive('ok', () => socket.disconnect())
  })

  // Finalize Session

  const { execute: doStartFinalizingSession } = useMutation(
    StartFinalizingSession,
  )
  const startFinalizingSession = () => {
    if (confirm('Zeker weten?')) {
      doStartFinalizingSession(variables).then(({ error }) => {
        if (error) {
          emit('set:flash', { kind: 'alert', msg: error.message })
        } else {
          channel.push('start_finalizing_session', {})
        }
      })
    }
  }

  const { execute: doFinalizeSession } = useMutation(FinalizeSession)
  const finalizeSession = () => {
    if (confirm('Zeker weten?')) {
      doFinalizeSession(variables).then(({ error }) => {
        if (error) {
          emit('set:flash', { kind: 'error', msg: error.message })
        } else {
          channel.push('finalize_session', {})
        }
      })
    }
  }

  // Delete Session

  const { execute: doDeleteSession } = useMutation(DeleteSession)
  const deleteSession = () => {
    if (confirm('Zeker weten?')) {
      doDeleteSession(variables).then(({ error }) => {
        if (error) {
          emit('set:flash', { kind: 'error', msg: error.message })
        } else {
          emit('set:flash', { kind: 'notice', msg: 'Assessment verwijderd' })
          channel.push('delete_session', {})
          router.push({ name: 'home' })
        }
      })
    }
  }

  // Create Result

  const { execute: doCreateResult } = useMutation(CreateResult)
  const createResult = (result: Result) => {
    doCreateResult(result).then(({ data, error }) => {
      if (error) {
        emit('set:flash', { kind: 'error', msg: error.message })
      } else {
        channel.push('create_result', data.createResult)
      }
    })
  }

  // Delete Result

  const { execute: doDeleteResult } = useMutation(DeleteResult)
  const deleteResult = (result: Result) => {
    doDeleteResult({ id: result.id }).then(({ error }) => {
      if (error) {
        emit('set:flash', { kind: 'error', msg: error.message })
      } else {
        channel.push('delete_result', result)
      }
    })
  }

  // Save Post

  const { execute: doSavePost } = useMutation(SavePost)
  const savePost = (post: ChangePost) => {
    doSavePost(post).then(({ data: postData, error }) => {
      if (error) {
        emit('set:flash', { kind: 'error', msg: error.message })
      } else {
        const session = data.value.session as Session
        const idx = session.posts.findIndex((p) => p.id === post.id)
        if (idx >= 0) {
          session.posts[idx] = postData.savePost
        } else {
          session.posts.push(postData.savePost)
        }
        emit('set:flash', { kind: 'notice', msg: 'Opgeslagen' })
      }
    })
  }

  // Create or delete Attachments

  const findAttachments = (goalId: string, postId: string): Attachment[] => {
    const goalIdx = data.value.session.goals.findIndex(
      (g: Goal) => g.id === goalId,
    )

    if (goalIdx) {
      const postIdx = data.value.session.goals[goalIdx].posts.findIndex(
        (p: Post) => p.id === postId,
      )

      return data.value.session.goals[goalIdx].posts[postIdx].attachments
    } else {
      return []
    }
  }

  const { execute: doCreateAttachment } = useMutation(CreateAttachment)
  const createAttachment = (newAttachment: AttachmentInput) => {
    doCreateAttachment(newAttachment).then(({ data, error }) => {
      if (error) {
        emit('set:flash', { kind: 'error', msg: error.message })
      } else {
        const attachments = findAttachments(
          newAttachment.goalId,
          newAttachment.postId,
        )

        const attachment = data.createAttachment
        attachment.newRecord = true
        attachments.push(attachment)

        emit('set:flash', { kind: 'notice', msg: 'Bijlage opgeslagen' })
      }
    })
  }

  const { execute: doDeleteAttachment } = useMutation(DeleteAttachment)
  const deleteAttachment = (attachmentInput: AttachmentInput) => {
    const attachments = findAttachments(
      attachmentInput.goalId,
      attachmentInput.postId,
    )
    const attachment = attachments[attachmentInput.idx]

    if (attachment.id) {
      doDeleteAttachment({ id: attachment.id }).then(({ error }) => {
        if (error) {
          emit('set:flash', { kind: 'error', msg: error.message })
        } else {
          emit('set:flash', { kind: 'notice', msg: 'Bijlage verwijderd' })
          attachments.splice(attachmentInput.idx, 1)
        }
      })
    } else {
      attachments.splice(attachmentInput.idx, 1)
    }
  }

  // Set Goals
  // TODO: Remove deleted goals.

  const setGoals = (goals: Array<Goal>) => {
    goals.forEach((newGoal) => {
      const idx = data.value.session.goals.findIndex(
        (g: Goal) => g.id === newGoal.id,
      )

      if (idx >= 0) {
        data.value.session.goals[idx] = newGoal

        const result = data.value.session.goals[idx].result

        if (result && result.level !== newGoal.level) {
          deleteResult(result)
        }
      } else {
        data.value.session.goals.push(newGoal)
      }
    })
  }

  useHead({
    title: () =>
      data.value ? `Assessment: ${data.value.session.title}` : null,
  })
</script>
